<template>
  <div class="p-05">
    <p
      class="has-text-primary has-text-centered has-text-weight-semibold"
      style="margin: 1em 0em"
    >
      Enter your criteria below:
    </p>
    <b-field>
      <b-dropdown v-model="environment" hoverable>
        <b-button slot="trigger" icon-left="server" type="is-primary">
          {{ $t('Environment') }}
        </b-button>
        <b-dropdown-item
          v-for="option in environmentList"
          :key="option.name"
          :value="option.name"
        >
          {{ option.friendlyName }}
        </b-dropdown-item>
      </b-dropdown>
      <b-input :value="friendlyName" disabled></b-input>
    </b-field>

    <b-field :label="$t('Parent Resource')">
      <b-input
        v-model="resourceParent"
        name="Parent Resource"
        icon="sitemap"
      ></b-input>
    </b-field>

    <b-field :label="$t('Travel time')">
      <b-input v-model="maxTime" name="Travel time" icon="car"></b-input>
    </b-field>

    <b-field :label="$t('Date for resource coverage')">
      <b-datepicker
        v-model="resourceDate"
        placeholder="Click to select..."
        name="Travel Date"
        icon="calendar"
        :date-formatter="$d"
      ></b-datepicker>
    </b-field>

    <strong>{{ $t('Travel start time') }}</strong>
    <b-field>
      <b-timepicker
        v-model="startDateTime"
        size="is-small"
        :increment-minutes="5"
        icon="clock"
      ></b-timepicker>
    </b-field>

    <b-field :label="$t('Activity heatmap start')">
      <b-datepicker
        v-model="activityDateFrom"
        placeholder="Select window start..."
        name="Activity Window Start"
        icon="calendar"
        :date-formatter="$d"
      ></b-datepicker>
    </b-field>

    <b-field :label="$t('Activity heatmap end')">
      <b-datepicker
        v-model="activityDateTo"
        placeholder="Select window end..."
        name="Activity Window End"
        icon="calendar-alt"
        :date-formatter="$d"
      ></b-datepicker>
    </b-field>
    <b-field :label="$t('Include non-scheduled activities')">
      <b-switch v-model="includeNonScheduled">
        {{ includeNonScheduled ? $t('Yes') : $t('No') }}
      </b-switch>
    </b-field>

    <b-field :label="$t('Home or Start?')">
      <b-switch v-model="startOrHome" true-value="Start" false-value="Home">
        {{ startOrHome }}
      </b-switch>
    </b-field>
    <b-field v-if="startOrHome === 'Home'" :label="$t('Home zone radius (km)')">
      <b-input v-model="radius" name="Radius" icon="ruler"></b-input>
    </b-field>

    <div class="buttons">
      <b-button type="is-primary" @click="onClose">Save</b-button>
      <b-button type="is-danger" @click="onDiscard"> Discard data </b-button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    startLocationCount: {
      type: Number,
      required: true,
      default: 0,
    },
    heatmap: {
      type: Boolean,
      required: true,
      default: false,
    },
    polygonCount: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      showHeatmap: 'On',
      showIsochrone: 'On',
      showResourcePin: 'On',
      showTraffic: 'Off',
      showTrafficIncidents: 'Off',
      showOnlyWorking: false,
    };
  },
  computed: {
    radius: {
      get() {
        return this.$store.getters['storeIsochrone/radius'];
      },
      set(value) {
        this.$store.dispatch('storeIsochrone/setRadius', value);
      },
    },
    startOrHome: {
      get() {
        return this.$store.getters['storeIsochrone/startOrHome'];
      },
      set(value) {
        this.$store.dispatch('storeIsochrone/setStartOrHome', value);
      },
    },
    resourceParent: {
      get() {
        return this.$store.getters['storeIsochrone/resourceParent'];
      },
      set(value) {
        this.$store.dispatch('storeIsochrone/setResourceParent', value);
      },
    },
    maxTime: {
      get() {
        return this.$store.getters['storeIsochrone/maxTime'];
      },
      set(value) {
        this.$store.dispatch('storeIsochrone/setMaxTime', value);
      },
    },
    includeNonScheduled: {
      get() {
        return this.$store.getters['storeIsochrone/includeNonScheduled'];
      },
      set(value) {
        this.$store.dispatch('storeIsochrone/setIncludeNonScheduled', value);
      },
    },
    environment: {
      get() {
        return this.$store.getters['storeIsochrone/environment'];
      },
      set(value) {
        this.$store.dispatch('storeIsochrone/setEnvironment', value);
      },
    },
    resourceDate: {
      get() {
        let epochBasedDate = this.$store.getters['storeIsochrone/resourceDate'];
        let resourceDate = new Date(epochBasedDate);
        return resourceDate;
      },
      set(val) {
        this.$store.dispatch('storeIsochrone/setResourceDate', val.valueOf());
      },
    },
    startDateTime: {
      get() {
        let unixTime = this.$store.getters['storeIsochrone/startDateTime'];
        if (unixTime) {
          return new Date(unixTime);
        } else {
          let newDate = new Date();
          newDate.setHours(7);
          newDate.setMinutes(0);
          return newDate;
        }
      },
      set(val) {
        let newDate;
        if (!this.isValidDate(val)) {
          newDate = new Date();
          newDate.setHours(7);
          newDate.setMinutes(0);
        } else {
          newDate = val.valueOf();
        }
        this.$store.dispatch('storeIsochrone/setStartDateTime', newDate);
      },
    },
    activityDateFrom: {
      get() {
        let unixTime = this.$store.getters['storeIsochrone/activityDateFrom'];
        if (unixTime) {
          let startObj = new Date(unixTime);
          return startObj;
        } else {
          return null;
        }
      },
      set(val) {
        let newDate = val.valueOf();
        this.$store.dispatch('storeIsochrone/setActivityDateFrom', newDate);
      },
    },
    activityDateTo: {
      get() {
        let unixTime = this.$store.getters['storeIsochrone/activityDateTo'];
        if (unixTime) {
          let startObj = new Date(unixTime);
          return startObj;
        } else {
          return null;
        }
      },
      set(val) {
        let newDate = val.valueOf();
        this.$store.dispatch('storeIsochrone/setActivityDateTo', newDate);
      },
    },
    friendlyName() {
      for (let i = 0; i < this.environmentList.length; i++) {
        if (this.environmentList[i].name === this.environment) {
          return this.environmentList[i].friendlyName;
        }
      }
      return null;
    },
  },

  watch: {
    searchCriteria: {
      handler: function (newVal) {
        this.localSearchCriteria = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    onClose() {
      this.$emit('close', false);
    },
    onDiscard() {
      this.$emit('discard');
    },
  },
});
</script>

<style scoped>
.p-05 {
  margin: 0.5rem;
}
</style>
