/*global H */

export function dragstart(evt) {
  if (evt.target instanceof H.map.Marker) this.behavior.disable();
}
export function dragend(evt) {
  if (evt.target instanceof H.map.Marker) {
    this.behavior.enable();
    const marker = evt.target;
    const geometry = marker.getGeometry();
    const markerData = marker.getData();
    const latLng = {
      home: {},
    };

    if (this.startOrHome === 'Start') {
      latLng.lat = geometry.lat;
      latLng.lng = geometry.lng;
    } else {
      latLng.home.lat = geometry.lat;
      latLng.home.lng = geometry.lng;
    }
    this.polylineGroup.forEach((polyline) => {
      const polylineData = polyline.getData();
      console.log(polylineData);
      if (polylineData) {
        if (polylineData.resourceId === markerData.resourceId) {
          this.polylineGroup.removeObject(polyline);
        }
      }
    });
    latLng.resourceId = markerData.resourceId;
    latLng.resourceType = markerData.type;
    this.getIsochrone(latLng, true);
  }
}

export function drag(evt) {
  const pointer = evt.currentPointer;
  if (evt.target instanceof H.map.Marker) {
    evt.target.setGeometry(
      this.map.screenToGeo(pointer.viewportX, pointer.viewportY),
    );
  }
}
