<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t('Resource Data') }}</p>
    </header>
    <section class="modal-card-body">
      <div style="height: 50vh; overflow-y: scroll; margin-bottom: 2em">
        <div v-for="resource in resourceData" :key="resource.resourceId">
          <resource-detail :resource="resource"> </resource-detail>
        </div>
      </div>

      <div class="buttons">
        <b-button
          v-clipboard:copy="csvResourceData"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          type="is-primary"
          >{{ $t('Copy CSV to clipboard') }}</b-button
        >
        <b-button
          v-clipboard:copy="stringifiedResourceData"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          type="is-primary"
          >{{ $t('Copy JSON to clipboard') }}</b-button
        >
        <b-button type="is-info" @click="$parent.close()">Close</b-button>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import Papa from 'papaparse';
import ResourceDetail from './ResourceDetail';

export default defineComponent({
  components: {
    ResourceDetail,
  },
  props: {
    resourceData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    stringifiedResourceData() {
      return JSON.stringify(this.resourceData, null, 2);
    },
    csvResourceData() {
      return Papa.unparse(this.stringifiedResourceData, {
        headers: true,
      });
    },
  },
  methods: {
    onCopy: function (e) {
      alert('You just copied: ' + e.text);
    },
    onError: function (e) {
      alert('Failed to copy texts', e);
    },
  },
});
</script>

<style></style>
