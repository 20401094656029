import axios from 'axios';
import configData from '../config/config.json';
import store from '../store/store-modules';
import moment from 'moment';
import { getIdToken } from '@/services/auth/index';

const apiClient = axios.create({
  baseURL: configData.isochrone.activityUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10 * 60 * 1000,
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

export function joinDateAndTime(datePart, timePart) {
  const datePartMoment = moment(datePart);
  const timePartMoment = moment(timePart);

  const dateString = datePartMoment.format('DD-MM-YYYY');
  const timeString = timePartMoment.format('HH:mm');
  const joinedString = `${dateString} ${timeString}`;
  const joinedMoment = moment(joinedString, 'DD-MM-YYYY HH:mm');
  return joinedMoment.toDate();
}

export async function getActivities({
  env,
  activityDateFrom,
  activityDateTo,
  customer,
  resourceParent,
  userId,
  includeNonScheduled = false,
}) {
  const params = new URLSearchParams();
  params.append('env', env);
  const dateFrom = moment(activityDateFrom).format('YYYY-MM-DD');
  const dateTo = moment(activityDateTo).format('YYYY-MM-DD');
  params.append('dateFrom', dateFrom);
  params.append('dateTo', dateTo);
  params.append('customer', customer);
  params.append('resources', resourceParent);
  params.append('includeChildren', 'all');
  params.append('fields', 'latitude,longitude,activityType');
  params.append('removeNonCustomer', 'YES');
  params.append('userId', userId);
  params.append('includeNonScheduled', includeNonScheduled.toString());

  const options = {
    method: 'GET',
    url: '',
    params,
  };
  const { data } = await apiClient(options);
  // Remove activities with junk lat/lngs
  const filteredActivities = data.filter((act) => {
    if (act.latitude === 0 && act.longitude === 0) return false;
    if (!(act.latitude && act.longitude)) return false;
    return true;
  });
  return store.dispatch('storeIsochrone/storeActivities', filteredActivities);
}

export async function getOnShift({
  env,
  resourceDate,
  startDateTime,
  customer,
  resourceIdAsCsv,
  userId,
}) {
  const params = new URLSearchParams();
  params.append('env', env);
  const travelDate = moment(joinDateAndTime(resourceDate, startDateTime));
  const travelDateString = travelDate.format('YYYY-MM-DD HH:mm');
  params.append('customer', customer);
  params.append('resources', resourceIdAsCsv);
  params.append('userId', userId);
  params.append('travelStart', travelDateString);

  const options = {
    method: 'GET',
    params,
    url: configData.isochrone.shiftUrl,
  };
  const { data } = await apiClient(options);

  return store.dispatch('storeIsochrone/storeOnShift', data);
}

export async function getStartLocs({
  env,
  customer,
  day,
  resourceParent,
  userId,
}) {
  const params = new URLSearchParams();
  params.append('env', env);
  params.append('customer', customer);
  params.append('day', day);
  params.append('resourceParent', resourceParent);
  params.append('userId', userId);
  const options = {
    method: 'GET',
    params,
    url: configData.isochrone.resourceUrl,
  };
  const { data } = await apiClient(options);
  return store.dispatch('storeIsochrone/storeStartLocations', data);
}

export async function getIsolineIntersects({ env, customer, userId, isoline }) {
  const params = new URLSearchParams();
  params.append('env', env);
  params.append('customer', customer);
  params.append('userId', userId);

  const options = {
    method: 'POST',
    params,
    url: configData.isochrone.intersectUrl,
    data: isoline,
  };
  const { data } = await apiClient(options);
  return data;
}
