/* eslint-disable no-undef */
<template>
  <div class="container is-fluid">
    <b-sidebar
      type="is-white"
      :fullheight="true"
      :fullwidth="false"
      :overlay="false"
      :right="false"
      :open.sync="open"
      v:on="$listeners"
    >
      <Iso-map-sidebar
        :start-location-count="startLocationCount"
        :heatmap="!!heatmap"
        :polygon-count="polygonCount"
        @close="open = false"
        @discard="discardData()"
      />
    </b-sidebar>
    <b-loading :active.sync="isLoading"></b-loading>
    <b-modal
      ref="imageModal"
      scroll="keep"
      width="1200"
      :active.sync="isImageModalActive"
    >
      <div id="imageModalCanvas"></div>
    </b-modal>
    <div style="margin-top: 1em; margin-bottom: 0em">
      <span class="flex-parent">
        <div class="flex-left buttons">
          <b-button
            type="is-primary"
            icon-left="arrow-to-left"
            @click="open = true"
            >Criteria</b-button
          >
          <b-button
            type="is-info"
            :disabled="!enableRetrieveStartLocs"
            @click="freshLocations"
            >{{ $t('Plot coverage') }}</b-button
          >
          <b-button
            class="is-info"
            :disabled="!enableRetrieveActivities"
            @click="getActivities"
            >{{ $t('Plot activities') }}</b-button
          >
          <b-button
            v-if="startLocations && startLocations.length > 0"
            type="is-info"
            @click="getOnShift"
            >{{ $t('Retrieve Calendars') }}</b-button
          >
          <b-button type="is-info" @click="showSummary">Summary</b-button>
          <b-tooltip
            :label="
              $t(
                'Right click -> save on the resulting pop-up to save the currently displayed map image',
              )
            "
            animated
            multilined
            type="is-dark"
          >
            <b-button type="is-info" @click="capture">Save Map Image</b-button>
            <b-button
              :disabled="polygonCount === 0"
              type="is-info"
              @click="getAllMarkerLatLngs"
              >{{ $t('Export Lat/Lngs') }}</b-button
            >
          </b-tooltip>
          <b-dropdown
            v-if="activities.length > 0"
            v-model="selectedActivityTypes"
            scrollable
            max-height="350px"
            style="margin-left: 0.5em"
            multiple
            aria-role="list"
            @active-change="handleActivityTypeDropdownEvent"
          >
            <button slot="trigger" class="button is-primary" type="button">
              <span>Activity Types</span>
              <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item
              v-for="activityType in availableActivityTypes"
              :key="activityType"
              :value="activityType"
              aria-role="listitem"
            >
              <span>{{ activityType }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="flex-right">
          <div style="display: flex">
            <b-field v-if="startLocationCount > 0" :label="$t('Pins')">
              <b-switch
                v-model="showResourcePin"
                true-value="On"
                false-value="Off"
                >{{ showResourcePin }}</b-switch
              >
            </b-field>
            <b-field
              v-if="polygonCount > 0"
              class="bf-mr-1"
              :label="$t('Coverage')"
            >
              <b-switch
                v-model="showIsochrone"
                true-value="On"
                false-value="Off"
                >{{ showIsochrone }}</b-switch
              >
            </b-field>
            <b-field v-if="heatmap" class="bf-mr-1" :label="$t('Heatmap')">
              <b-switch
                v-model="showHeatmap"
                true-value="On"
                false-value="Off"
                >{{ showHeatmap }}</b-switch
              >
            </b-field>
            <b-field
              v-if="startLocationCount > 0"
              class="bf-mr-1"
              :label="$t('On shift')"
            >
              <b-switch
                v-model="showOnlyWorking"
                :true-value="true"
                :false-value="false"
                >{{ showOnlyWorking ? 'Yes' : 'No' }}</b-switch
              >
            </b-field>
          </div>
        </div>
      </span>
    </div>
    <p v-if="error" class="is-size-5 help is-danger">{{ error }}</p>
    <span v-if="polygonCount > 0">{{ $t('Draggable Guide') }}</span>

    <p v-if="isochroneError" class="is-size-5 help is-danger">
      {{ isochroneError }}
    </p>
    <div id="mapContainer" ref="map" :style="normalMapStyle">
      <div v-if="polygonCount > 0" class="legend">
        <div class="flex-parent legend-margin-top legend-margin-left">
          <div style="height: 24; width: 24" v-html="normalIcon" />
          <div>{{ $t('In-house') }}</div>
        </div>
        <div class="flex-parent legend-margin-left">
          <div style="height: 24; width: 24" v-html="contractorIcon" />
          <div>{{ $t('Contractor') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*global H */
import { defineComponent } from '@vue/composition-api';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { backOff } from 'exponential-backoff';
import axios from 'axios';

import configData from '@/config/config.json';
import IsoMapSidebar from './IsoMapSidebar';
import IsoMapSummary from './IsoMapDataSummary';
import IsoMapExport from './IsoMapExport';

import loadJs from '@/lib/loadJs';
import loadCss from '@/lib/loadCss';

import {
  getActivities,
  getOnShift,
  getStartLocs,
  joinDateAndTime,
  getIsolineIntersects,
} from '@/services/isochroneService';

import moment from 'moment';

const contractorTypes = configData.isochrone.contractorTypes;
// const contractorColor = "rgba(251, 84, 33, 0.2)";
// const normalColor = "rgba(129, 123, 213,0.2)";

const contractorIconColor = 'rgba(251, 84, 33, 1.0)';
const normalIconColor = 'rgba(129, 123, 213, 1.0)';
// eslint-disable-next-line max-len
const normalIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="${normalIconColor}"  d="M14.24 4.559c1.705 2.816-1.52 5.378-3.858 3.539 1.902.49 4.171-1.303 3.858-3.539zm-1.24 7.351v10.09l-2 2v-12.09c-2.836-.477-5-2.938-5-5.91 0-3.314 2.687-6 6-6s6 2.687 6 6c0 2.972-2.164 5.433-5 5.91zm3-5.91c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4 4-1.794 4-4z"/></svg>`;
// eslint-disable-next-line max-len
const contractorIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="${contractorIconColor}" d="M14.24 4.559c1.705 2.816-1.52 5.378-3.858 3.539 1.902.49 4.171-1.303 3.858-3.539zm-1.24 7.351v10.09l-2 2v-12.09c-2.836-.477-5-2.938-5-5.91 0-3.314 2.687-6 6-6s6 2.687 6 6c0 2.972-2.164 5.433-5 5.91zm3-5.91c0-2.206-1.794-4-4-4s-4 1.794-4 4 1.794 4 4 4 4-1.794 4-4z"/></svg>`;

import { drag, dragend, dragstart } from './dragEventListeners';

export default defineComponent({
  name: 'ResourceCoverage',
  components: {
    IsoMapSidebar,
  },
  data() {
    return {
      normalMapStyle: {
        height: '71vh',
        marginBottom: '0.5em',
      },
      normalIcon,
      contractorIcon,
      isImageModalActive: false,
      open: false,
      map: {},
      platform: {},
      defaultLayers: {},
      behavior: {},
      markerGroup: {},
      router: {},
      search: {},
      ui: {},
      center: {
        lat: this.$configData.isochrone.defaultCentre.lat,
        lng: this.$configData.isochrone.defaultCentre.lng,
      },
      polylineGroup: null,
      isLoading: false,
      pushPins: [],
      heatmap: null,
      showHeatmap: 'On',
      showIsochrone: 'On',
      showResourcePin: 'On',
      showOnlyWorking: false,
      error: null,
      isochroneError: null,
      polygonErrorCount: 0,
      initComplete: false,
      showSummaryModal: false,
      eventListeners: {},
      selectedActivityTypes: [],
    };
  },
  computed: {
    ...mapGetters('storeHereMaps', [
      'coreLoaded',
      'serviceLoaded',
      'uiLoaded',
      'eventsLoaded',
      'uiCssLoaded',
      'allLoaded',
    ]),
    ...mapGetters('storeIsochrone', [
      'startLocations',
      'startLocationCount',
      'activities',
      'activityCount',
      'activityDateFrom',
      'activityDateTo',
      'startDateTime',
      'resourceParent',
      'environment',
      'maxTime',
      'shifts',
      'includeNonScheduled',
      'startOrHome',
      'radius',
      'polygons',
    ]),
    polygonCount() {
      if (!this.polylineGroup) return 0;
      const objects = this.polylineGroup.getObjects();
      return objects.length;
    },
    availableActivityTypes() {
      const actsWithTypeOnly = this.activities.map((activity) => {
        return activity.activityType;
      });
      const actTypeSet = new Set(actsWithTypeOnly);
      return Array.from(actTypeSet);
    },
    includedActivities() {
      if (!this.activities) return [];
      return this.activities.filter((activity) => {
        return this.selectedActivityTypes.includes(activity.activityType);
      });
    },
    queryDateString() {
      if (!this.resourceDate) return '';
      const queryDate = this.$moment(this.resourceDate);
      queryDate.hour(this.startDateTimeMoment.hour());
      queryDate.minute(this.startDateTimeMoment.minutes());
      queryDate.second(0);
      return queryDate.format('YYYY-MM-DDTHH:mm:ss');
    },
    resourceIdAsCsv() {
      const resourceArray = this.startLocations.map((loc) => {
        return loc.resourceId;
      });
      return this.convertArrayToCSV(resourceArray);
    },
    enableRetrieveActivities() {
      return this.activityDateFrom && this.activityDateTo && this.environment;
    },
    enableRetrieveStartLocs() {
      return this.environment && this.resourceDate && this.resourceParent;
    },
    day() {
      if (this.resourceDate) {
        let dateMoment = this.$moment(this.resourceDate);
        return dateMoment.format('ddd').toLowerCase();
      } else {
        return null;
      }
    },
    resourceDate: {
      get() {
        let epochBasedDate = this.$store.getters['storeIsochrone/resourceDate'];
        let resourceDate = new Date(epochBasedDate);
        return resourceDate;
      },
      set(val) {
        this.$store.dispatch('storeIsochrone/setResourceDate', val.valueOf());
      },
    },
    startDateTimeMoment() {
      return this.startDateTime
        ? this.$moment(this.startDateTime)
        : this.$moment();
    },
  },
  watch: {
    activities: {
      handler: function () {
        this.selectedActivityTypes = this.availableActivityTypes.map((type) => {
          return type;
        });
      },
    },
    showIsochrone(newVal) {
      if (newVal === 'Off') {
        this.map.removeObject(this.polylineGroup);
      } else {
        this.map.addObject(this.polylineGroup);
      }
    },
    async showOnlyWorking() {
      await this.getOnShift();
      await this.getIsochrones();
      await this.plotStartLocs();
    },
    showResourcePin(newVal) {
      if (newVal === 'Off') {
        try {
          this.map.removeObject(this.markerGroup);
        } catch (err) {
          /* ignore */
        }
      } else {
        this.plotStartLocs();
      }
    },
    showHeatmap(newVal) {
      if (newVal === 'On') {
        this.map.addLayer(this.heatmap);
      } else {
        this.map.removeLayer(this.heatmap);
      }
    },
  },
  created() {
    if (!this.allLoaded) {
      loadJs(
        'https://js.api.here.com/v3/3.1/mapsjs-core.js',
        () => {
          this.SET_CORE_LOADED(true);
        },
        document.body,
        false,
        true,
      );
    }
    loadJs(
      'https://js.api.here.com/v3/3.1/mapsjs-service.js',
      () => {
        this.SET_SERVICE_LOADED(true);
      },
      document.body,
      false,
      true,
    );
    loadJs(
      'https://js.api.here.com/v3/3.1/mapsjs-ui.js',
      () => {
        this.SET_UI_LOADED(true);
      },
      document.body,
      false,
      true,
    );
    loadJs(
      'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js',
      () => {
        this.SET_EVENTS_LOADED(true);
      },
      document.body,
      false,
      true,
    );
    loadCss(
      'https://js.api.here.com/v3/3.1/mapsjs-ui.css',
      () => {
        this.SET_UI_CSS_LOADED(true);
      },
      document.head,
    );

    loadJs(
      'https://js.api.here.com/v3/3.1/mapsjs-clustering.js',
      () => {
        this.SET_CLUSTERING_LOADED(true);
      },
      document.body,
      false,
      true,
    );
    loadJs(
      'https://js.api.here.com/v3/3.1/mapsjs-data.js',
      () => {
        this.SET_MAP_DATA_LOADED(true);
      },
      document.body,
      false,
      true,
    );
    this.fetchEnvironments(false);
  },
  mounted() {
    this.getMap(() => {
      this.initMap(() => {});
    });
  },
  beforeDestroy() {
    this.removeAllListeners();
  },
  methods: {
    ...mapMutations('storeHereMaps', [
      'SET_CORE_LOADED',
      'SET_SERVICE_LOADED',
      'SET_UI_LOADED',
      'SET_EVENTS_LOADED',
      'SET_UI_CSS_LOADED',
      'SET_CLUSTERING_LOADED',
      'SET_MAP_DATA_LOADED',
    ]),
    ...mapActions('storeIsochrone', ['addPolygon']),
    drag,
    dragstart,
    dragend,
    getPolygon(id) {
      return this.polygons[id];
    },
    getAllMarkerLatLngs() {
      const markers = this.markerGroup.getObjects();
      const polygons = this.polylineGroup.getObjects();
      this.mergePolygonAndMarkerData(polygons, markers);
      const results = markers.map((marker) => {
        const data = marker.getData();
        const geometry = marker.getGeometry();
        return {
          ...data,
          ...geometry,
        };
      });
      this.$buefy.modal.open({
        parent: this,
        component: IsoMapExport,
        props: {
          resourceData: results,
        },
        hasModalCard: true,
      });
    },
    mergePolygonAndMarkerData(polygons, markers) {
      const markerCount = markers.length;
      for (let i = 0; i < this.polygonCount; i++) {
        const polygonData = polygons[i].getData();
        for (let j = 0; j < markerCount; j++) {
          const markerData = markers[j].getData();
          if (markerData.resourceId === polygonData.resourceId) {
            markerData.intersects = polygonData.intersects;
            break;
          }
        }
      }
    },
    handleActivityTypeDropdownEvent(event) {
      if (!event && this.activities.length > 0) {
        this.startClustering();
      }
    },
    removeAllListeners() {
      const keys = Object.keys(this.eventListeners);
      keys.forEach((key) => {
        this.map.removeEventListener('drag', this.eventListeners[key].drag);
        this.map.removeEventListener(
          'drag',
          this.eventListeners[key].dragstart,
        );
        this.map.removeEventListener('drag', this.eventListeners[key].dragend);
      });
    },
    isContractor(resource) {
      return contractorTypes.includes(resource.resourceType);
    },
    async makeDraggable(markerObj) {
      const loc = markerObj.getData();
      markerObj.draggable = true;
      if (this.eventListeners[loc.resourceId]) {
        return;
      }
      this.eventListeners[loc.resourceId] = {};
      this.eventListeners[loc.resourceId].dragstart = this.dragstart;
      this.eventListeners[loc.resourceId].dragend = this.dragend;
      this.eventListeners[loc.resourceId].drag = this.drag;

      this.map.addEventListener(
        'dragstart',
        this.eventListeners[loc.resourceId].dragstart,
        {
          passive: true,
        },
      );
      this.map.addEventListener(
        'dragend',
        this.eventListeners[loc.resourceId].dragend,
        {
          passive: true,
        },
      );
      this.map.addEventListener(
        'drag',
        this.eventListeners[loc.resourceId].drag,
        {
          passive: true,
        },
      );
    },
    showPostcodes() {
      const service = this.platform.getPlatformDataService();
      const bubble = new H.ui.InfoBubble(this.map.getCenter(), {
        content: '',
      });
      bubble.close();
      this.ui.addBubble(bubble);

      const style = new H.map.SpatialStyle();
      // create tile provider and layer that displays postcode boundaries
      const boundariesProvider =
        new H.service.extension.platformData.TileProvider(
          service,
          {
            layerId: 'PSTLCB_UNGEN',
            level: 12,
          },
          {
            resultType:
              H.service.extension.platformData.TileProvider.ResultType.POLYLINE,
            styleCallback: function () {
              return style;
            },
          },
        );
      // eslint-disable-next-line no-undef
      const boundaries = new H.map.layer.TileLayer(boundariesProvider);
      this.map.addLayer(boundaries);

      const svg = `<svg width="80" height="24" xmlns="http://www.w3.org/2000/svg"><text x="0" y="20" font-size="10pt" font-family="Arial">{POSTCODE}</text></svg>`;

      // create tile provider and layer that displays postcode centroids
      const centroidsProvider =
        new H.service.extension.platformData.TileProvider(
          service,
          {
            layerId: 'PSTLCB_MP',
            level: 12,
          },
          {
            resultType:
              H.service.extension.platformData.TileProvider.ResultType.MARKER,
            styleCallback(data) {
              const postcode = data.getCell('POSTAL_CODE');
              const postcodeSvg = svg.replace('{POSTCODE}', postcode);
              return new H.map.Icon(postcodeSvg);
            },
          },
        );
      const centroids = new H.map.layer.MarkerTileLayer(centroidsProvider);
      this.map.addLayer(centroids);

      // add event listener that shows infobubble with basic information
      // about the postcode
      centroidsProvider.addEventListener('tap', this.handlePostcodeTap, false);
    },
    handlePostcodeTap(evt) {
      const marker = evt.target;
      let bubble = new H.ui.InfoBubble(marker.getGeometry());
      const data = marker.getData();
      const str = `
        ${data.getCell('POSTAL_CODE')}
        <br/>
        ${data.getCell('ADMIN_5')}
        <br/>
        ${data.getCell('ADMIN_4')}
        <br/>
        ${data.getCell('ADMIN_3')}
        <br/>
        ${data.getCell('ADMIN_2')}`;
      bubble.setContent(str);

      this.ui.addBubble(bubble);
    },
    handleTap(evt) {
      const marker = evt.target;
      const resourceData = marker.getData();
      const geometry = marker.getGeometry();
      this.search.reverseGeocode(
        {
          at: `${geometry.lat},${geometry.lng}`,
        },
        (result) => {
          let address = result?.items[0]?.address;
          if (!address) {
            address = {
              label: 'Address not found',
            };
          }
          const bubble = new H.ui.InfoBubble(geometry, {
            content: `
              <div style="width: auto">
                <p class="has-text-primary has-text-weight-semibold">${
                  resourceData ? resourceData.name : 'Name unknown'
                }</p>
                <p class="has-text-primary has-text-weight-semibold">${
                  resourceData ? resourceData.type : 'Type unknown'
                }</p>
                <p class="has-text-primary has-text-weight-semibold">Lat/Lng: ${
                  geometry ? geometry.lat : 'Lat unknown'
                }/${geometry ? geometry.lng : 'Lng unknown'}</p>
                <p class="has-text-primary has-text-weight-semibold">${
                  address.label
                }</p>
                <p class="has-text-primary has-text-weight-semibold is-italic">Now draggable</p>
              </div>`,
          });
          marker.setData({
            ...resourceData,
            address: address.label,
          });
          this.makeDraggable(marker);
          this.ui.addBubble(bubble);
        },
        console.error,
      );
    },
    showSummary() {
      this.$buefy.modal.open({
        parent: this,
        component: IsoMapSummary,
        props: {
          startLocationCount: this.startLocationCount,
          areaCountSuccess: this.polygonCount,
          areaCountFailed: this.polygonErrorCount,
          dateOfCoverage: joinDateAndTime(
            this.resourceDate,
            this.startDateTime,
          ),
          activityCount: this.activityCount,
          activityDateFrom: new Date(this.activityDateFrom),
          activityDateTo: new Date(this.activityDateTo),
        },
        hasModalCard: true,
      });
    },
    initMap(callback) {
      if (this.initComplete) callback();
      else {
        this.platform = new H.service.Platform({
          apikey: this.$configData.mapConfig.apiKey,
        });
        this.markerGroup = new H.map.Group();
        this.pixelRatio = window.devicePixelRatio || 1;
        this.defaultLayers = this.platform.createDefaultLayers({
          tileSize: this.pixelRatio === 1 ? 256 : 512,
          ppi: this.pixelRatio === 1 ? undefined : 320,
        });
        this.search = this.platform.getSearchService();
        this.defaultLayers.crossOrigin = true;
        this.map = new H.Map(
          this.$refs.map,
          this.defaultLayers.vector.normal.map,
          {
            zoom: this.$configData.isochrone.defaultCentre.zoom || 8,
            center: this.center,
            pixelRatio: this.pixelRatio,
          },
        );
        window.addEventListener('resize', () =>
          this.map.getViewPort().resize(),
        );
        this.behavior = new H.mapevents.Behavior(
          new H.mapevents.MapEvents(this.map),
        );

        // Create the default UI components
        this.ui = H.ui.UI.createDefault(this.map, this.defaultLayers);
        this.ui.setUnitSystem(
          this.imperial ? H.ui.UnitSystem.IMPERIAL : H.ui.UnitSystem.METRIC,
        );
        this.map.addObject(this.markerGroup);
        this.router = this.platform.getRoutingService();
        this.initComplete = true;
        callback();
        this.showPostcodes();
      }
    },
    getMap(callback) {
      let vm = this;
      function checkIfLoaded() {
        if (vm.allLoaded) callback();
        else setTimeout(checkIfLoaded, 200);
      }
      checkIfLoaded();
    },
    convertToMapObject(objects) {
      const hasObjects =
        objects.getObjects != null &&
        typeof objects.getObjects() !== 'undefined' &&
        objects.getObjects().length >= 1;

      // if the parsed element has nested map elements, call this function again
      if (hasObjects) {
        objects.getObjects().forEach((innerObject) => {
          this.convertToMapObject(innerObject);
        });
      } else {
        // otherwise, add the parsed map element to the Group object
        objects.setStyle(
          new H.map.SpatialStyle({
            style: {
              fillColor: '#EA4335',
              strokeColor: '#829',
              lineWidth: 8,
            },
          }),
        );
      }
      return objects;
    },
    capture() {
      // Capturing area of the map is asynchronous, callback function receives HTML5 canvas
      // element with desired map area rendered on it.
      // We also pass an H.ui.UI reference in order to see the ScaleBar in the output.
      // If dimensions are omitted, whole veiw port will be captured
      this.map.capture(
        (canvas) => {
          if (canvas) {
            this.isImageModalActive = true;
            this.$nextTick(() => {
              let div = document.getElementById('imageModalCanvas');
              div.appendChild(canvas);
            });
          } else {
            // For example when map is in Panorama mode
            this.$buefy.modal.open(`<p>'Capturing is not supported'</p>`);
          }
        },
        [this.ui],
      );
    },
    discardActivities() {
      this.$store.dispatch('storeIsochrone/discardActivities');
    },
    removeHeatmap() {
      if (this.heatmap) {
        this.map.removeLayer(this.heatmap);
      }
    },
    discardData() {
      const bubbles = this.ui.getBubbles();
      while (bubbles.length > 0) {
        this.ui.removeBubble(bubbles[0]);
      }

      this.$store.dispatch('storeIsochrone/discardAllData');
      this.discardMarkerGroup();
      this.discardIsochronesOnly();
      this.removeHeatmap();
      this.discardActivities();
      this.polygonErrorCount = 0;
      this.map.clearContent();
    },
    discardIsochronesOnly() {
      if (this.polylineGroup) {
        try {
          this.map.removeObject(this.polylineGroup);
        } catch (e) {
          /* ignore */
        }
      }
    },
    async getActivities() {
      this.discardActivities();
      try {
        this.isLoading = true;
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Retrieving activities',
          type: 'is-primary',
        });
        await getActivities({
          env: this.environment,
          activityDateFrom: this.activityDateFrom,
          activityDateTo: this.activityDateTo,
          customer: this.customer,
          resourceParent: this.resourceParent,
          userId: this.userId,
          includeNonScheduled: this.includeNonScheduled,
        });
        this.$buefy.toast.open({
          duration: 3000,
          message: `Retrieved ${this.activityCount} activities`,
          type: 'is-success',
          queue: true,
        });

        this.startClustering();
      } catch (validatorError) {
        console.error(validatorError);
      } finally {
        this.isLoading = false;
      }
    },
    async getOnShift() {
      try {
        this.isLoading = true;
        await getOnShift({
          env: this.environment,
          startDateTime: this.startDateTime,
          resourceDate: this.resourceDate,
          customer: this.customer,
          resourceIdAsCsv: this.resourceIdAsCsv,
          userId: this.userId,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    startClustering() {
      this.removeHeatmap();
      this.isLoading = true;

      const dataPoints = [];
      this.includedActivities.forEach((item) => {
        if (item.latitude && item.longitude) {
          let dataPoint = new H.clustering.DataPoint(
            item.latitude,
            item.longitude,
          );
          if (dataPoint) {
            dataPoints.push(dataPoint);
          }
        }
      });
      const pixelRatio = window.devicePixelRatio || 1;

      // Create a clustering provider with custom options for clusterizing the input
      const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
        pixelRatio: pixelRatio,
        clusteringOptions: {
          // Maximum radius of the neighbourhood
          eps: 32,
          // minimum weight of points required to form a cluster
          minWeight: 2,
        },
      });

      // Create a layer that will consume objects from our clustering provider
      this.heatmap = new H.map.layer.ObjectLayer(clusteredDataProvider);

      // To make objects from clustering provder visible,
      // we need to add our layer to the map
      this.map.addLayer(this.heatmap);
      this.isLoading = false;
    },
    async getStartLocs() {
      try {
        this.error = null;
        this.isLoading = true;
        await getStartLocs({
          env: this.environment,
          startDateTime: this.startDateTime,
          customer: this.customer,
          day: this.day,
          resourceParent: this.resourceParent,
          userId: this.userId,
        });
        if (!this.startLocations || this.startLocations.length === 0) {
          this.$buefy.toast.open({
            duration: 5000,
            message: `${this.$t(
              `No start locations found for ${this.resourceParent}`,
            )}`,
            position: 'is-top',
            type: 'is-danger',
          });
        }
      } catch (err) {
        this.error = err.response.data || err.message;
      } finally {
        this.isLoading = false;
      }
    },
    async freshLocations() {
      try {
        this.error = null;
        this.isLoading = true;
        if (!this.startLocations || this.startLocations.length === 0) {
          this.$buefy.toast.open({
            duration: 10000,
            message: `${this.$t(
              'Please hold on, this may take a couple of minutes',
            )}`,
            position: 'is-top',
            type: 'is-success',
          });
          await this.getStartLocs();
        }

        await this.getIsochrones();
        await this.plotStartLocs();
      } catch (error) {
        console.log(error?.response?.status);
        if (error.response.status === 404) {
          this.error = `Resource ${this.resourceParent} not found`;
        } else if (error?.response?.status === 401) {
          this.error = `Invalid OFSC credentials for ${this.environment}`;
        } else {
          this.error = error.message;
        }
      } finally {
        this.isLoading = false;
      }
    },
    discardMarkerGroup() {
      if (this.markerGroup) {
        try {
          this.markerGroup.removeEventListener('tap', this.handleTap, false);
          this.markerGroup.removeAll();
          this.map.removeObject(this.markerGroup);
        } catch (err) {
          // don't mind if this fails
        }
      }
    },
    async plotStartLocs() {
      this.discardMarkerGroup();
      if (!this.markerGroup) {
        this.markerGroup = new H.map.Group();
      }

      this.markerGroup.addEventListener('tap', this.handleTap, {
        passive: true,
      });

      if (this.showResourcePin === 'On') {
        this.map.addObject(this.markerGroup);
      }
      const mapNormalIcon = new H.map.Icon(this.normalIcon);
      const mapContractorIcon = new H.map.Icon(this.contractorIcon);

      let icon;
      this.startLocations.forEach((loc) => {
        if (this.showOnlyWorking && !loc.isWorking) {
          return;
        }
        if (this.isContractor(loc)) {
          icon = mapContractorIcon;
        } else {
          icon = mapNormalIcon;
        }
        let lat, lng;
        if (this.startOrHome === 'Start') {
          lat = loc.lat;
          lng = loc.lng;
        } else {
          lat = loc.home.lat;
          lng = loc.home.lng;
        }
        const center = new H.geo.Point(lat, lng);
        const markerPos = new H.map.Marker(center, { icon });
        // Add the polygon and marker to the map:
        markerPos.setData({
          resourceId: loc.resourceId,
          name: loc.name,
          type: loc.resourceType,
          address: '',
        });
        markerPos.setZIndex(999);

        this.markerGroup.addObject(markerPos);
      });
      this.zoomToBoundingBox();
    },
    zoomToBoundingBox() {
      this.map.getViewModel().setLookAtData({
        bounds: this.markerGroup.getBoundingBox(),
      });
    },
    async buildLineString(result, resourceId) {
      const mapNormalIcon = new H.map.Icon(this.normalIcon);
      const isolineCoords = result.response.isoline[0].component[0].shape;
      const center = new H.geo.Point(
        result.response.center.latitude,
        result.response.center.longitude,
      );
      // Add the returned isoline coordinates to a linestring:
      const linestring = new H.geo.LineString();
      isolineCoords.forEach((coords) => {
        linestring.pushLatLngAlt.apply(linestring, coords.split(','));
      });

      // Create a polygon and a marker representing the isoline:
      const isolinePolygon = new H.map.Polygon(linestring);

      const isolineCenter = new H.map.Marker(center, {
        icon: mapNormalIcon,
      });
      // Add the polygon and marker to the map:
      this.markerGroup.addObject(isolineCenter);
      this.polylineGroup.addObject(isolinePolygon);

      try {
        const intersects = await getIsolineIntersects({
          env: this.environment,
          customer: this.customer,
          userId: this.userId,
          isoline: isolinePolygon.toGeoJSON(),
        });
        console.log(intersects);

        isolinePolygon.setData({
          resourceId,
          intersects,
        });
      } catch (err) {
        /* don't want to prevent isochrone rendering if this fails */
      }
    },
    async getIsochrone(loc, isMove = false) {
      let queryDate = moment(this.resourceDate);
      queryDate.hour(this.startDateTimeMoment.hour());
      queryDate.minute(this.startDateTimeMoment.minutes());
      queryDate.second(0);
      let queryDateString = queryDate.format('YYYY-MM-DDTHH:mm:ss');

      console.log(`processing: ${loc.name}-${loc.resourceId}`);
      const routingParams = {
        mode: 'fastest;car;traffic:enabled',
        start: `geo!${loc.lat},${loc.lng}`,
        range: this.maxTime * 60,
        rangetype: 'time',
        departure: queryDateString,
        routingMode: 'fast',
        apiKey: this.$configData.mapConfig.apiKey,
      };
      const params = new URLSearchParams(routingParams);
      const url = `https://isoline.route.ls.hereapi.com/routing/7.2/calculateisoline.json`;

      const existing = this.getPolygon(loc.resourceId);
      console.log(`${loc.resourceId}-${!!existing}`);
      if (!isMove && existing !== undefined) {
        this.buildLineString(existing, loc.resourceId);
        return;
      }
      // Call the Routing API to calculate an isoline:
      try {
        const { data } = await backOff(
          () =>
            axios.request({
              method: 'get',
              url,
              params,
            }),
          {
            numOfAttempts: Infinity,
            timeMultiple: 1.2,
            maxDelay: 5000,
            startingDelay: 10,
          },
        );
        await this.addPolygon({
          resourceId: loc.resourceId,
          response: {
            center: data.response.center,
            isoline: data.response.isoline,
          },
        });
        this.buildLineString(data, loc.resourceId);
      } catch (err) {
        console.error(`Failed to isoline`, err);
      }
    },
    async getIsochrones() {
      this.isochroneError = null;
      if (this.polylineGroup) {
        try {
          this.map.removeObject(this.polylineGroup);
        } catch (err) {
          // don't mind if this fails
        }
      }
      if (this.markerGroup) {
        try {
          this.map.removeObject(this.markerGroup);
        } catch (err) {
          // don't mind if this fails
        }
      }
      try {
        this.isochroneCount = 0;
        this.isLoading = true;
        const startLocs = this.$store.getters['storeIsochrone/startLocations'];
        this.polylineGroup = new H.map.Group();
        this.markerGroup = new H.map.Group();

        this.map.addObject(this.polylineGroup);
        this.map.addObject(this.markerGroup);

        // Define a callback function to process the isoline response

        startLocs.forEach(async (loc) => {
          await this.getIsochrone(loc);
        });
      } catch (err) {
        this.isochroneError = err.message;
        console.error('failed processing isochrones', err);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>

<style scoped>
.flex-parent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.flex-left {
  justify-content: flex-start;
}

.flex-right {
  justify-content: end;
  margin-left: auto;
}

.bf-mr-1 {
  margin-right: 1em;
}

.legend {
  position: absolute;
  right: 3%;
  top: 16.5%;
  z-index: 10;
  border-style: none;
  height: 100px;
  width: 170px;
  background-color: white;
  border-radius: 10%;
  -webkit-box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
  -moz-box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
  box-shadow: 0em 0 0.4em 0 rgba(15, 22, 33, 0.6);
}

.legend-margin-left {
  margin-left: 1em;
}

.legend-margin-top {
  margin-top: 1em;
}
</style>
