<template>
  <div class="modal-card" style="width: 30vw">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t('Summary') }}</p>
    </header>
    <section class="modal-card-body">
      <p class="has-text-weight-semibold">
        {{ $t('Start Locations') }}: {{ startLocationCount }}
      </p>
      <p class="has-text-weight-semibold">
        {{ $t('Coverage Areas') }}: {{ areaCountSuccess }}
      </p>
      <p class="has-text-weight-semibold">
        {{ $t('Failed Areas') }}: {{ areaCountFailed }}
      </p>
      <p class="has-text-weight-semibold">
        {{ $t('Coverage Datetime') }}: {{ coverageDateString }} ({{
          resourceDay
        }})
      </p>
      <p class="has-text-weight-semibold">
        {{ $t('Activities Retrieved') }}:
        {{ activityCount }}
      </p>
      <p
        v-if="activityDateFrom && activityDateTo"
        class="has-text-weight-semibold"
      >
        {{ $t('Activity Date Range') }}: {{ formatDate(activityDateFrom) }} -
        {{ formatDate(activityDateTo) }}
      </p>
      <b-field class="has-text-centered" style="margin-top: 1em">
        <b-button type="is-primary" @click="onClose">{{
          $t('Close')
        }}</b-button>
      </b-field>
    </section>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    startLocationCount: {
      type: Number,
      required: true,
      default: 0,
    },
    areaCountSuccess: {
      type: Number,
      required: true,
      default: 0,
    },
    areaCountFailed: {
      type: Number,
      required: true,
      default: 0,
    },
    dateOfCoverage: {
      type: [String, Date],
      required: true,
      default: '',
    },
    activityCount: {
      type: Number,
      required: true,
      default: 0,
    },
    activityDateFrom: {
      type: [Date, String],
      required: true,
      default: '',
    },
    activityDateTo: {
      type: [Date, String],
      required: true,
      default: '',
    },
  },
  computed: {
    coverageDateString() {
      if (!this.dateOfCoverage) return '';
      const momentDate = this.$moment(this.dateOfCoverage);
      return momentDate.format('DD-MM-YYYY hh:mm');
    },
    resourceDay() {
      if (this.dateOfCoverage) {
        return this.$moment(this.resourceDate).format('dddd');
      } else {
        return '';
      }
    },
  },
  methods: {
    onClose() {
      this.$parent.close();
    },
    formatDate(date, long = false) {
      let type = long ? 'long' : 'short';
      if (date) {
        return this.$d(date, type);
      } else {
        return '';
      }
    },
  },
});
</script>
