<template>
  <div class="div-pb1">
    <p class="has-text-weight-semibold">
      {{ $t('Resource') }}:
      <span class="has-text-weight-normal"
        >{{ resource.name }} ({{ resource.resourceId }})</span
      >
    </p>
    <p class="has-text-weight-semibold">
      {{ $t('Type') }}:
      <span class="has-text-weight-normal">{{ resource.type }}</span>
    </p>
    <p v-if="resource.address" class="has-text-weight-semibold">
      {{ $t('Address:') }}
      <span class="has-text-weight-normal">{{ resource.address }}</span>
    </p>
    <p class="has-text-weight-semibold">
      {{ $t('Lat/Lng') }}:
      <span class="has-text-weight-normal"
        >{{ resource.lat }},{{ resource.lng }}</span
      >
    </p>
    <p class="has-text-weight-semibold">
      {{ $t('Postcode District Intersects') }}:
      <span class="has-text-weight-normal">{{
        JSON.stringify(resource.intersects)
      }}</span>
    </p>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    resource: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
});
</script>

<style>
.div-pb1 {
  padding-bottom: 1em;
}
</style>
